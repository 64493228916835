import React, { useState } from "react";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/light";
import Box from "@onnit-js/ui/components/box/Box";
import Text, { List, ListItem, Sup } from "@onnit-js/ui/components/text";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import usePageData from "../../hooks/usePageData";
import { Img, Picture } from "@onnit-js/ui/components/image";
import ProductImageGrid from "@onnit-js/ui/components/module/ProductImageGrid";
import Grid, { TwelveColumn } from "@onnit-js/ui/components/module/grid/12/Grid";
import Embed from "@onnit-js/ui/components/video/Embed";
import DefaultProductPageTop from "../../components/common/layouts/DefaultProductPageTop";
import SupplementFacts from "../../components/common/SupplementFacts";
import UsageAndDisclaimers from "../../components/common/UsageAndDisclaimers";
import config from "../../config/config";
import Product from "@onnit-js/ui/@types/interfaces/product/BuyBoxProduct";
import SimpleBuyBox from "@onnit-js/ui/components/buybox/simple/SimpleBuyBox";
import ImageContentPortrait from "@onnit-js/ui/components/module/ImageContentPortrait";
import MediaContentShowcase from "@onnit-js/ui/components/module/MediaContentShowcase";
import ingredients from "./ingredients";

function transformProductName(name: string): string {
    return name.replace(/Plant-Based Protein - /gi, "");
}

function Page() {
    const onnitContext = useOnnitContext();
    const { pageData } = usePageData();
    //used to set current products supplement facts image.
    const [currentPID, setCurrentPID] = useState<number | undefined>();

    if (!pageData || !onnitContext) return null;

    const IMG_URL = onnitContext.url.cdn.static_images + "/product-page/plant-based-protein";
    const {IMG_PARAMS} = config;
    const mainColor = "#9DDDBC";

    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyle />
                <DefaultProductPageTop
                    productNameTransform={transformProductName}
                    onProductChange={(product) => {
                        setCurrentPID(product.id);
                    }}
                >
                    <ProductImageGrid>
                        <Picture
                            lazyLoad={false}
                            width={1943}
                            height={873}
                            alt="2 containers of onnit whey protein"
                            src={[
                                `${IMG_URL}/2024-PBP-Wpage-Gallery-1-920x920.png${IMG_PARAMS}`,
                                `${IMG_URL}/2024-PBP-Wpage-Gallery-1-1940x873.png${IMG_PARAMS}`,
                            ]}
                        />
                        <Img
                            alt="chocolate onnit whey protein"
                            src={`${IMG_URL}/2024-PBP-Wpage-Gallery-2-920x920.png${IMG_PARAMS}`}
                            lazyLoad={false}
                        />
                        <Img
                            alt="vanilla onnit whey protein"
                            src={`${IMG_URL}/2024-PBP-Wpage-Gallery-3-920x920.png${IMG_PARAMS}`}
                            lazyLoad={false}
                        />
                        <Picture
                            lazyLoad={false}
                            width={1943}
                            height={873}
                            alt="collection of edible plants"
                            src={[
                                `${IMG_URL}/2024-PBP-Wpage-Gallery-4-920x920.png${IMG_PARAMS}`,
                                `${IMG_URL}/2024-PBP-Wpage-Gallery-4-1940x873.png${IMG_PARAMS}`,
                            ]}
                        />
                    </ProductImageGrid>
                </DefaultProductPageTop>
                <Box bg="#161616" mt={[0, 0, 6, 8]} py={[6, 6, 6, 8]}>
                    <TwelveColumn display={["none", "none", "grid"]} mb={4}>
                        <Box gridColumn={["1 / span 6", "1 / span 6", "1 / span 6", "2 / span 7"]} px={4}>
                            <Text as="h2" color="grays.3" typeStyle="text02" fontWeight="regular">
                                Plant-Based Protein
                            </Text>
                        </Box>
                    </TwelveColumn>
                    <TwelveColumn display={["block", "block", "grid"]} mb={[0, 0, 6]}>
                        <Box
                            gridColumn={["7 / span 6", "7 / span 6", "7 / span 6", "7 / span 5"]}
                            px={4}
                            mb={[6, 6, 0]}
                        >
                            <Embed
                                title="Plant Based Protein Product Video"
                                src="https://player.vimeo.com/video/372685998"
                            />
                        </Box>
                        <Box
                            gridColumn={["1 / span 6", "1 / span 6", "1 / span 6", "2 / span 5"]}
                            display={["block", "block", "flex"]}
                            alignItems="center"
                            px={4}
                        >
                            <Text
                                display={["block", "block", "none"]}
                                as="h2"
                                color="grays.3"
                                typeStyle="text02"
                                fontWeight="regular"
                                my={3}
                            >
                                Plant-Based Protein
                            </Text>

                            <Text as="h3" typeStyle="title01" color={mainColor} mb={2}>
                                Garden of Gainz
                            </Text>
                        </Box>
                    </TwelveColumn>

                    <TwelveColumn display={["block", "block", "grid"]}>
                        <Box
                            gridColumn={["1 / span 6", "1 / span 6", "1 / span 6", "2 / span 7"]}
                            px={4}
                            mb={[6, 6, 6, 8]}
                        >
                            <Text as="p" color="white" typeStyle="text02" mb={4} fontWeight="regular">
                                Every athlete and gym rat knows it: if you want to help support muscle gain, body
                                recomp, or recovery after exercise, you need a protein supplement. Whey has long stood
                                at the top of the heap, but it doesn’t stand alone.
                                <Sup/>
                            </Text>
                            <Text as="p" color="white" typeStyle="text02" mb={4} fontWeight="regular">
                                Onnit’s Plant-Based Protein gives those who eat plant-based or dairy-free another
                                protein option—derived from peas and pumpkin, sunflower, and watermelon seeds.
                            </Text>
                        </Box>
                        <Box gridColumn={["2 / span 10", "2 / span 10", "2 / span 10", "3 / span 10"]} px={4}>
                            <Text as="h3" typeStyle="title01" color={mainColor} mb={4}>
                                Plant-Based Protein is for:
                            </Text>
                            <List>
                                <ListItem typeStyle="text02" color="white" fontWeight="regular" mb={3}>
                                    Athletes
                                </ListItem>
                                <ListItem typeStyle="text02" color="white" fontWeight="regular" mb={3}>
                                    Gym goers
                                </ListItem>
                                <ListItem typeStyle="text02" color="white" fontWeight="regular" mb={3}>
                                    People who have trouble eating enough protein on a plant-based diet
                                </ListItem>
                                <ListItem typeStyle="text02" color="white" fontWeight="regular">
                                    Those who follow a plant-based diet
                                </ListItem>
                            </List>
                        </Box>
                    </TwelveColumn>
                </Box>
                <Box py={[6, 6, 6, 8]}>
                    <Text as="h3" typeStyle="title01" textAlign="center" mb={6}>
                        Available in two flavors
                    </Text>
                    <Box display="flex" justifyContent="center">
                        {pageData.products.map((product: Product, index) => (
                            <Box flex="1 1 50%" maxWidth="364px" px={[4, 4, 6]} key={index}>
                                <SimpleBuyBox
                                    product={product}
                                    additionalImgixParams="crop=bottom,center&fit=crop&h=0.45"
                                    productNameTransform={transformProductName}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Box bg="#161616" py={[6, 6, 6, 8]}>
                    <Grid display={["block", "block", "block", "grid"]} px={5} mb={6}>
                        <Text
                            as="h3"
                            typeStyle="title02"
                            color="white"
                            gridColumn={["1 / -1", "1 / -1", "2 / span 12"]}
                        >
                            Key Features
                        </Text>
                    </Grid>
                    <MediaContentShowcase
                        showImages={false}
                        items={ingredients.map((x) => {
                            let copy = {...x};
                            copy.image = `${IMG_URL}/${copy.image}`;
                            return copy;
                        })}
                        activeColor={mainColor}
                        textColor="white"
                        bgMedia={
                            <Img
                                alt="collection of nuts and seeds"
                                src={`${IMG_URL}/2024-PBP-Wpage-Ingredient-Background.png${IMG_PARAMS}`}
                                width={1120}
                                height={315}
                            />
                        }
                    />
                </Box>

                <ImageContentPortrait
                    py={6}
                    reverse={true}
                    image={
                        <Img
                            src={`${IMG_URL}/2024-PBP-Wpage-MoreMuscle-920x920.png${IMG_PARAMS}`}
                            alt="Tub of Onnit Plant-Based protein"
                            placeholder={[920, 920]}
                        />
                    }
                    content={
                        <Box>
                            <Text typeStyle="title02" mb={6}>
                                More Muscle, No Milk
                            </Text>
                            <Text typeStyle="text02" color="grays.5" mb={4} fontWeight="regular">
                                Most plant-based powders don’t offer complete amino acid profiles. They either don’t
                                contain all the essential amino acids (EAAs), or they’re low in one or more of them, and
                                that makes them less effective.
                            </Text>

                            <Text as="p" typeStyle="text02" color="grays.5" mb={4} fontWeight="regular">
                                Onnit's Plant-Based Protein is different. It has aminos derived from a variety of plant
                                sources, so it provides the full spectrum of EAAs that plant-based dieters need and
                                can’t get from many plant-protein supplements.
                            </Text>
                            <Text as="p" typeStyle="text02" color="grays.5" fontWeight="regular">
                                Whether you’re vegetarian, vegan, or just looking to reduce the animal products in your
                                diet, you don’t have to sacrifice results anymore.
                            </Text>
                        </Box>
                    }
                />
                <TwelveColumn display={["block", "block", "block", "grid"]}>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / -1", "2 / span 10", "3 / span 8"]} px={4}>
                        <Picture
                            lazyLoad={true}
                            width={944}
                            height={531}
                            alt="Chart of typical amino acids per serving"
                            src={[
                                `${IMG_URL}/2024-PBP-Wpage-No-Milk-Mobile.png${IMG_PARAMS}`,
                                `${IMG_URL}/2024-PBP-Wpage-No-Milk-Desktop.png${IMG_PARAMS}`,
                            ]}
                        />
                        <Text typeStyle="title02" my={6}>
                            Level Up With Leucine
                        </Text>
                        <Text as="p" typeStyle="text02" fontWeight="regular" color="grays.5" mb={3}>
                            Leucine is a branched-chain amino acid (BCAA) that many scientists believe is the most
                            important amino of all for supporting muscle growth. According to the International Society
                            of Sports Nutrition, the optimal amount of leucine for stimulating muscle gains is between
                            0.7 and 3 grams per serving of protein.<span className="oui-sup">1</span>
                        </Text>
                        <Text as="p" typeStyle="text02" fontWeight="regular" color="grays.5" mb={3}>
                            One scoop of whey protein usually has 2-3 grams of leucine, while many plant-protein powders
                            are much lower, and that reduces their muscle-building potential.
                            <Sup/>
                        </Text>
                        <Text as="p" typeStyle="text02" fontWeight="regular" color="grays.5" mb={3}>
                            However, Onnit’s Plant-Based Protein has extra leucine added, so it can stand up alongside
                            whey.
                            <Sup/>
                        </Text>
                        <Text as="p" typeStyle="text02" fontWeight="regular" color="grays.5" mb={8}>
                            The table below shows how Onnit's Plant-Based Protein compares to our Grass-Fed Whey Protein
                            Isolate. The numbers per serving are virtually equal.
                        </Text>

                        <Text typeStyle="title02" color="black" mb={6}>
                            Plant vs Whey
                        </Text>
                        <Picture
                            lazyLoad={true}
                            width={944}
                            height={531}
                            alt="plant based protein nutrition facts"
                            src={[
                                `${IMG_URL}/2023-PBP-Wpage-Leucine-Mobile.png${IMG_PARAMS}`,
                                `${IMG_URL}/2023-PBP-Wpage-Leucine-Desktop.png${IMG_PARAMS}`,
                            ]}
                        />
                    </Box>
                </TwelveColumn>
                <UsageAndDisclaimers
                    supplementWarning={pageData.hasFDAWarning}
                    supplementFacts={<SupplementFacts currentPID={currentPID} />}
                >
                    <Box mt={2}>
                        <Text as="p" fontSize={0} color="grays.5" fontWeight="heavy" display="inline" mr={1}>
                            ♦ NOTICE:
                        </Text>
                        <Text
                            as="p"
                            fontSize={0}
                            display="inline"
                            fontWeight="heavy"
                            typeStyle="default"
                            color="grays.5"
                            mb={4}
                        >
                            Not a low calorie food. Use this product as a food supplement only. Do not use for weight
                            reduction.
                        </Text>
                    </Box>
                </UsageAndDisclaimers>
                <Box py={[6, 6, 6, 8]} display="flex" justifyContent="center" mx={4}>
                    <Box display="flex" flexDirection="column" maxWidth="736px">
                        <Text as="p" typeStyle="text04" mb={4} color="grays.6">
                            REFERENCES
                        </Text>
                        <Text as="p" typeStyle="body" mb={4} color="grays.6">
                            1. Jäger, Ralf, et al. "International society of sports nutrition position stand: protein
                            and exercise." Journal of the International Society of Sports Nutrition 14.1 (2017): 20.
                        </Text>
                    </Box>
                </Box>
            </>
        </ThemeProvider>
    );
}

export default Page;
