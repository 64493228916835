import {MediaContentShowcaseItem} from "@onnit-js/ui/components/module/MediaContentShowcase";

const ingredientsFlowBlend: MediaContentShowcaseItem[] = [
    {
        image: "",
        imageWidth: 560,
        imageHeight: 560,
        title: 'Smooth Protein<span class="oui-sup">&trade;</span>',
        summary: "Vegan proteins derived from pea as well as pumpkin, sunflower, and watermelon seeds.  ",
        detail:
            'Smooth Protein<span class="oui-sup">&trade;</span> helps mask the grittiness of pea protein, making for a creamier consistency.',
    },
    {
        image: "",
        imageWidth: 560,
        imageHeight: 560,
        title: "Avocado Oil Powder",
        summary: "A keto-friendly fat source.",
        detail:
            "Many other plant protein powders are known for their earthy taste. Avocado oil powder contributes healthy fats that enhance flavor and mouthfeel, making Plant-Based Protein delicious.",
    },
    {
        image: "",
        imageWidth: 560,
        imageHeight: 560,
        title: "Monk Fruit Extract",
        summary: "A naturally-derived, zero-calorie sweetener that’s sweeter than sugar cane.",
        detail: "Monk fruit extract provides a natural sweetness without additional calories. ",
    },
];

export default ingredientsFlowBlend;
